<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Today Visit</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.showMap`]="{ item }">
                                    <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="showItemMap(item)" >Map <v-icon right dark>mdi-google-maps</v-icon></v-btn>
                                </template>
                            </v-data-table>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-body">
                    <div id="mapCanvas" style="height:500px;"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Sales Activity',
                disabled: false,
                href: '#',
                },
                {
                text: 'Today Visit',
                disabled: true,
                href: '#',
                },
            ],
            itemLists: [],
            headers: [
                { text: 'CUSTOMER ID', value: 'customerid', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER NAME', value: 'namacustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ADDRESS', value: 'alamat', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CITY', value: 'city', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE', value: 'tr_date', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'GPS ADDRESS', value: 'lt_location', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'remark', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showMap', align: 'center', class: 'primary--text blue lighten-5' },
            ],    
            searchItem: '',
            loadingDatatable: false,
            mymap: '',
            userGroup: null,
            appl_id: 'SALES ACTIVITY'
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)
            axios.get(`${process.env.VUE_APP_URL}/api/gbrk/TodayVisit`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
                .then(res => {
                    
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        showItemMap(item){
            if (this.mymap != '') {
                this.mymap.off()
                this.mymap.remove()
            }
            $('#detailModal').modal('show')

            this.mymap = L.map('mapCanvas').setView([item.sales_latitude, item.sales_longitude], 15);
        
            L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KKA}`, {
                maxZoom: 18,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                    '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                    'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.mymap)

            L.marker([item.sales_latitude, item.sales_longitude],
                {draggable: false,
                //title: 'Hover Text',
                opacity: 1}
                )
                .addTo(this.mymap)
                .bindPopup('<b>Your GPS location</b><br> ')
                .openPopup()

        }

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>